@import url("components/ui-dialog.css");
@import url("components/ui-datepicker.css");
@import url("components/ui-text-search.css");
/**/
/**/
/* elements */
/**/
/* line 7, ../scss/master.scss */
:root {
  overflow-y: scroll !important;
  /* does not work for IE8 */ }

/* line 8, ../scss/master.scss */
body {
  background: #fff linear-gradient(to top, #cee1f0 0%, white 100%) no-repeat top left/100% 127px;
  color: #181818;
  border: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.75em;
  /* 12px */
  line-height: 1.25em;
  margin: 0;
  padding: 0;
  text-align: center; }

/* line 19, ../scss/master.scss */
iframe {
  display: block;
  background: #fff;
  border: none;
  width: 100%;
  height: 670px; }

/* line 26, ../scss/master.scss */
img {
  border: 0;
  vertical-align: middle; }

/* line 30, ../scss/master.scss */
a {
  color: #039;
  text-decoration: none; }

/* line 34, ../scss/master.scss */
a:focus {
  outline: none; }

/* line 35, ../scss/master.scss */
a:hover {
  color: #039;
  text-decoration: underline; }

/* line 39, ../scss/master.scss */
a.named-anchor {
  left: auto;
  position: absolute;
  top: auto;
  visibility: hidden; }

/* line 45, ../scss/master.scss */
table,
table tr th,
table tr td {
  border-collapse: collapse; }

/* line 50, ../scss/master.scss */
.full-width {
  width: 100%; }

/* line 53, ../scss/master.scss */
.spacer {
  height: 1px; }

/* line 56, ../scss/master.scss */
.spacer.line {
  border-bottom: 1px solid #CCC;
  padding-bottom: 10px;
  margin-bottom: 10px; }

/* line 61, ../scss/master.scss */
.details-item table tr th,
.details-item table tr td {
  padding-right: 10px; }

/* line 65, ../scss/master.scss */
.details-item td.release {
  text-align: center; }

/* line 68, ../scss/master.scss */
p {
  margin: 0;
  margin-bottom: 1em; }

/* line 72, ../scss/master.scss */
small {
  font-size: 92%;
  /* 11px */ }

/* line 73, ../scss/master.scss */
ul,
ol {
  margin: 0;
  margin-bottom: 1em;
  padding: 0; }

/* line 79, ../scss/master.scss */
ul li,
ol li {
  margin-left: 18px; }

/* line 81, ../scss/master.scss */
dl {
  margin: 0;
  margin-bottom: 1em; }

/* line 85, ../scss/master.scss */
dl dt,
dl dd {
  margin: 0; }

/* line 87, ../scss/master.scss */
hr {
  margin: 0;
  margin-bottom: 1em;
  border: 0;
  border-top: 1px solid #dedede;
  height: 1px; }

/* line 94, ../scss/master.scss */
form {
  border: 0;
  margin: 0;
  padding: 0;
  width: auto; }

/* line 100, ../scss/master.scss */
fieldset {
  border: 0;
  display: block;
  line-height: 2.6em;
  margin: 0;
  padding: 0; }

/* line 107, ../scss/master.scss */
input[type="text"],
select,
textarea {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
  padding: 3px;
  border: solid 1px #AAA;
  background-color: #FFF; }

/* line 117, ../scss/master.scss */
input[type="text"] {
  padding: 3px; }

/* line 118, ../scss/master.scss */
input[type="checkbox"] {
  vertical-align: middle; }

/* line 119, ../scss/master.scss */
input[type="button"],
input[type="submit"],
input[type="reset"] {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  color: #000;
  padding: 4px;
  border: 0 none;
  cursor: pointer; }

/* line 122, ../scss/master.scss */
input:focus,
select:focus {
  outline: none; }

/* line 124, ../scss/master.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }

/* line 133, ../scss/master.scss */
h1,
h2 {
  color: #cfc8c3;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase; }

/* line 140, ../scss/master.scss */
h1 {
  font-size: 167%;
  /* 20px */
  letter-spacing: normal; }

/* line 144, ../scss/master.scss */
h2 {
  font-size: 133%;
  /* 16px */
  letter-spacing: 1px; }

/* line 148, ../scss/master.scss */
h3,
h4,
h5,
h6 {
  font-size: 100%;
  /* 12px */ }

/**/
/**/
/* classes */
/**/
/* line 155, ../scss/master.scss */
.clear:after {
  clear: both;
  content: "\00A0";
  /*'&nbsp;'*/
  display: block;
  font-size: 0px;
  height: 0px;
  line-height: 0px;
  overflow: hidden;
  visibility: hidden; }

/* line 165, ../scss/master.scss */
span.color-approved,
td.color-approved {
  color: #208900 !important; }

/* line 170, ../scss/master.scss */
span.color-declined,
td.color-declined {
  color: #ea0000 !important; }

/* line 175, ../scss/master.scss */
span.color-pending,
td.color-pending {
  color: #939393 !important; }

/* line 180, ../scss/master.scss */
.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-top: 95px;
  position: relative;
  text-align: left;
  width: 100%; }

/* line 188, ../scss/master.scss */
.main {
  margin-bottom: 10px;
  min-height: 420px;
  position: relative; }

/* line 193, ../scss/master.scss */
.main .section {
  min-height: 200px;
  padding: 0 0 0 10px; }

/* line 197, ../scss/master.scss */
.nav {
  width: 10%; }

/* line 200, ../scss/master.scss */
.variable-width {
  margin-left: 195px;
  min-width: 800px; }

/* line 204, ../scss/master.scss */
.variable-width > .main-tabs + div {
  float: left;
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 10px; }

/* line 205, ../scss/master.scss */
.main .section .main-tabs {
  background-color: #004e90;
  height: 37px; }
  /* line 209, ../scss/master.scss */
  .main .section .main-tabs:before {
    content: " ";
    position: absolute;
    right: 0;
    left: 0;
    width: 205px;
    height: 37px;
    background: #004e90; }

/* line 219, ../scss/master.scss */
.main .section .main-tabs ul {
  list-style: none;
  height: 37px;
  overflow: hidden;
  padding-left: 20px; }

/* line 225, ../scss/master.scss */
.main .section .main-tabs li {
  color: #fff;
  float: left;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  margin-top: 7px;
  margin-right: 30px;
  margin-left: 0; }

/* line 235, ../scss/master.scss */
.main .section .main-tabs li a {
  color: #fff; }

/* line 236, ../scss/master.scss */
.main .section .main-tabs li.active,
.main .section .main-tabs li.active a,
.main .section .main-tabs li.active a span {
  background-image: url(../Images/active-tab.png);
  background-repeat: no-repeat; }

/* line 242, ../scss/master.scss */
.main .section .main-tabs li.active {
  background-position: left top;
  padding-left: 4px; }

/* line 246, ../scss/master.scss */
.main .section .main-tabs li.active a {
  background-position: right top;
  color: #181818;
  cursor: default;
  display: block;
  height: 30px;
  padding-right: 4px;
  text-decoration: none; }

/* line 255, ../scss/master.scss */
.main .section .main-tabs li.active a span {
  background-position: center top;
  display: block;
  padding: 0 5px; }

/* line 260, ../scss/master.scss */
.main .section .secondary-actions {
  background-color: #c0d6e9;
  height: 30px;
  color: #181818;
  padding: 0 10px;
  margin-top: 0;
  width: auto;
  float: none; }

/* line 269, ../scss/master.scss */
.secondary-actions a.new-change-single-multiple {
  float: right;
  line-height: 30px;
  display: block;
  text-decoration: none;
  color: #039;
  font-size: 92%;
  /* 11px */
  font-weight: bold; }

/* line 278, ../scss/master.scss */
.secondary-actions a.new-change-single-multiple:hover {
  text-decoration: underline; }

/* line 279, ../scss/master.scss */
.secondary-actions ul.list-actions {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 10px; }

/* line 284, ../scss/master.scss */
.secondary-actions ul.list-actions li {
  background-color: #181818;
  background-image: url(../Images/ul-list-actions.gif);
  background-position: right 50%;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 92%;
  /* 11px */
  font-weight: bold;
  float: left;
  line-height: 20px;
  margin-left: 0;
  margin-right: 5px;
  margin-top: 5px;
  padding-left: 8px;
  padding-right: 24px;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

/* line 304, ../scss/master.scss */
.secondary-actions ul.list-actions > li > span {
  cursor: pointer; }

/* line 307, ../scss/master.scss */
.secondary-actions ul.list-actions li ul {
  background-color: #181818;
  border-top: 2px solid #181818;
  display: none;
  left: 0;
  list-style-type: none;
  margin-left: 0;
  padding-bottom: .5em;
  padding-top: 1.5em;
  position: absolute;
  top: 18px;
  z-index: 2; }

/* line 320, ../scss/master.scss */
.secondary-actions ul.list-actions li.actions ul {
  width: 200px;
  border-radius: 0 4px 4px 4px; }

/* line 324, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter input {
  margin-left: 0; }

/* line 327, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter ul {
  width: 280px;
  border-radius: 0 4px 4px 4px; }

/* line 331, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter ul li {
  margin-bottom: 3px; }

/* line 332, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter ul li [type="text"] {
  width: 137px; }

/* line 333, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter ul li .datepicker {
  width: 63px; }

/* line 334, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter ul li .select2-container {
  width: 56% !important; }

/* line 335, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter ul li .select2-choice {
  width: 96%; }

/* line 336, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter ul li .select2-drop .select2-search > input {
  width: 100%; }

/* line 337, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter ul li .select2-drop .select2-result {
  margin-left: 1px;
  margin-right: 0;
  width: 87.1%; }

/* line 338, ../scss/master.scss */
.secondary-actions ul.list-actions li.filter ul span {
  width: 100px;
  display: inline-block;
  background: none; }

/* line 343, ../scss/master.scss */
.secondary-actions ul.list-actions li ul li {
  background-color: transparent;
  background-image: none;
  float: none;
  font-size: 109%;
  /* 12px */
  font-weight: normal;
  margin-top: 0;
  padding-right: 8px;
  color: #d4d4d4;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

/* line 356, ../scss/master.scss */
.secondary-actions ul.list-actions li ul li a {
  color: #fff; }

/* line 359, ../scss/master.scss */
ol.paging {
  color: #000;
  float: right;
  font-size: 92%;
  /* 11px */
  font-weight: bold;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 8px; }

/* line 368, ../scss/master.scss */
ol.paging li {
  float: left;
  margin-left: 8px;
  text-align: center;
  width: 14px; }

/* line 374, ../scss/master.scss */
ol.paging li span {
  color: #6b7780; }

/* line 377, ../scss/master.scss */
ol.paging li a {
  color: #000;
  display: block; }

/* line 381, ../scss/master.scss */
ol.paging li.first,
ol.paging li.previous,
ol.paging li.next,
ol.paging li.last,
ol.paging li.first a,
ol.paging li.previous a,
ol.paging li.next a,
ol.paging li.last a {
  background-image: url(../Images/ol-paging.png);
  background-repeat: no-repeat;
  text-indent: -999em; }

/* line 393, ../scss/master.scss */
ol.paging li.first {
  background-position: left -142px; }

/* line 394, ../scss/master.scss */
ol.paging li.previous {
  background-position: left -175px; }

/* line 395, ../scss/master.scss */
ol.paging li.next {
  background-position: left -208px; }

/* line 396, ../scss/master.scss */
ol.paging li.last {
  background-position: left -241px; }

/* line 397, ../scss/master.scss */
ol.paging li.first a {
  background-position: left -10px; }

/* line 398, ../scss/master.scss */
ol.paging li.previous a {
  background-position: left -43px; }

/* line 399, ../scss/master.scss */
ol.paging li.next a {
  background-position: left -76px; }

/* line 400, ../scss/master.scss */
ol.paging li.last a {
  background-position: left -109px; }

/* line 401, ../scss/master.scss */
.wrapper .main .section .secondary-actions ol.paging li.off a {
  cursor: default; }

/* a.pageChange {text-decoration:underline;} */
/* line 403, ../scss/master.scss */
.main .section .main-content {
  min-height: 400px; }

/* navigation */
/* line 407, ../scss/master.scss */
.main .nav {
  width: 200px;
  float: left;
  margin-top: 74px;
  min-height: 498px; }

/* line 413, ../scss/master.scss */
.main .nav .main-menu {
  z-index: 999;
  position: relative; }

/* line 417, ../scss/master.scss */
.main .nav ul {
  list-style: none;
  margin: 0;
  padding: 5px;
  overflow: hidden; }

/* line 423, ../scss/master.scss */
.main .nav ul li {
  margin-left: 0;
  background-color: #7FB2DD;
  border-radius: 4px;
  margin-bottom: 3px;
  position: relative; }

/* line 430, ../scss/master.scss */
.main .nav ul li:hover {
  background-color: #004E90; }

/* line 431, ../scss/master.scss */
.main .nav ul li a {
  color: #FFF;
  text-decoration: none;
  font-weight: bold;
  padding: 0 5px;
  line-height: 34px;
  display: block; }

/* line 439, ../scss/master.scss */
.main .nav ul li.active {
  background-color: #c5df26;
  cursor: default; }

/* line 440, ../scss/master.scss */
.main .nav ul li.active a {
  color: #222;
  cursor: default; }

/* line 441, ../scss/master.scss */
.main .nav ul ul {
  padding: 0;
  display: none; }

/* line 442, ../scss/master.scss */
.main .nav ul li.active ul {
  padding: 0;
  display: block; }

/* line 443, ../scss/master.scss */
.main .nav ul li.expanded:hover {
  background-color: #7FB2DD; }

/* line 444, ../scss/master.scss */
.main .nav ul li.parent a {
  color: #fff; }

/* line 445, ../scss/master.scss */
.main .nav ul li.parent ul li.inactive a {
  cursor: pointer; }

/* line 446, ../scss/master.scss */
.main .nav ul li.active.parent {
  background-color: #7FB2DD; }

/* line 447, ../scss/master.scss */
.main .nav ul li.active.parent ul li.active a {
  color: #222;
  cursor: default; }

/* line 448, ../scss/master.scss */
.main .nav ul ul li a {
  font-weight: normal; }

/* line 449, ../scss/master.scss */
.main .nav ul ul li {
  margin-left: 20px;
  margin-right: 5px; }

/* line 453, ../scss/master.scss */
.main .nav ul li.parent {
  background-image: url("../Images/ul-list-actions.gif");
  background-position: right 15px;
  background-repeat: no-repeat; }

/* line 458, ../scss/master.scss */
.main .nav ul li.parent.expanded ul {
  display: block; }

/* navigation */
/* line 460, ../scss/master.scss */
.main h1 {
  left: 30px;
  line-height: 60px;
  position: absolute;
  top: 30px;
  z-index: 1; }

/* line 467, ../scss/master.scss */
.main p {
  padding-left: 10px;
  padding-right: 10px; }

/* line 471, ../scss/master.scss */
.wrapper .header {
  height: 95px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  /* line 478, ../scss/master.scss */
  .wrapper .header a.logo {
    display: block;
    height: 95px;
    position: absolute;
    left: 10px;
    top: 0;
    width: 220px; }
    /* line 486, ../scss/master.scss */
    .wrapper .header a.logo img {
      margin-top: 33px; }

/* line 493, ../scss/master.scss */
.wrapper .header .supplier-account {
  position: relative;
  right: 0;
  top: 34px;
  font-weight: bold;
  color: #181818;
  text-align: right;
  float: right; }

/* line 502, ../scss/master.scss */
.supplier-account {
  padding-right: 10px; }

/* line 505, ../scss/master.scss */
.wrapper .header .supplier-account span {
  padding-right: 5px; }

/* line 506, ../scss/master.scss */
.wrapper .header .supplier-account a {
  padding-left: 5px;
  text-decoration: none;
  color: #181818; }

/* line 511, ../scss/master.scss */
.wrapper .header .supplier-account a:hover {
  text-decoration: underline; }

/* line 512, ../scss/master.scss */
.wrapper .footer {
  color: #999;
  margin-bottom: 2em;
  line-height: 30px;
  font-size: 92%;
  /* 11px */
  border-top: solid 1px #d4d4d4;
  width: 100%; }

/* line 520, ../scss/master.scss */
.wrapper .footer .copy {
  line-height: 30px;
  text-align: center;
  width: 100%; }

/* line 521, ../scss/master.scss */
.form-buttons {
  border-top: solid 1px #d4d4d4;
  clear: both;
  overflow: hidden;
  text-align: right;
  padding: 10px 0; }

/* line 528, ../scss/master.scss */
.form-buttons input,
.new-request .selected-item-add input[type='button'],
.new-request input[type='button'],
.supplier-list input[type="button"],
.arrangement-search input[type="button"],
.arrangement-results input[type="button"] {
  border: 0;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  height: 30px;
  margin-right: 10px;
  width: 150px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

/* line 545, ../scss/master.scss */
.form-buttons input {
  margin-left: 10px;
  margin-right: 0; }

/* line 546, ../scss/master.scss */
.form-buttons input:first-child {
  margin-left: 0; }

/* line 547, ../scss/master.scss */
.form-buttons input.plain {
  width: auto;
  float: none; }

/* line 551, ../scss/master.scss */
.form-buttons .clear-bttn,
.new-request .selected-item-add input[type='button'],
.new-request input[type='button'] {
  background-color: #d4d4d4;
  color: #181818;
  margin-right: 10px; }

/* line 558, ../scss/master.scss */
.form-buttons .save-bttn {
  background-color: #181818;
  color: #fff; }

/* line 562, ../scss/master.scss */
.form-buttons .save-bttn[disabled] {
  background-color: #a8a8a8; }

/* line 565, ../scss/master.scss */
.form-buttons .hotel-email-buttons {
  display: none;
  border-top: solid 1px #dedede;
  margin-top: 1em;
  padding-top: 1em; }

/* line 571, ../scss/master.scss */
.form-buttons .hotel-email-buttons input[type='text'] {
  width: 400px;
  text-align: right;
  height: 18px;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
  padding: 3px;
  border: none;
  background-color: #FFF;
  cursor: text; }

/* line 583, ../scss/master.scss */
.form-buttons .hotel-email-buttons input[type='text']:disabled {
  cursor: default; }

/* line 586, ../scss/master.scss */
.form-buttons .hotel-email-buttons input[type='button'] {
  background-color: #181818;
  color: #fff;
  width: 50px; }

/* line 591, ../scss/master.scss */
.form-buttons .hotel-email-buttons input[type="button"]:disabled {
  background-color: #a8a8a8;
  width: 50px; }

/* line 595, ../scss/master.scss */
.user-info {
  display: inline-block;
  width: 100px;
  position: absolute;
  left: 0px; }

/* MAIN-CONTENT */
/* line 602, ../scss/master.scss */
table.message-list {
  width: 100%;
  margin-top: 20px; }

/* line 606, ../scss/master.scss */
table.message-list th,
table.message-list td {
  text-align: left;
  vertical-align: middle; }

/* line 611, ../scss/master.scss */
table.message-list th {
  background-image: url(../Images/small-header-bar.png);
  background-position: left bottom;
  background-repeat: repeat-x;
  border-bottom: solid 1px #e6e6e6;
  border-top: solid 1px #e6e6e6;
  color: #939393;
  font-size: 92%;
  /* 11px */
  height: 21px;
  max-height: 21px;
  padding-left: 5px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap; }

/* line 626, ../scss/master.scss */
table.message-list th.col-roomtype:before,
table.message-list th.col-date:before,
table.message-list th.col-status:before,
table.message-list th.col-extradate:before {
  border-left: 1px solid #d4d4d4;
  content: "";
  height: 80%;
  margin-right: 5px; }

/* line 635, ../scss/master.scss */
table.message-list th.col-input:after {
  content: "";
  height: 80%;
  border-right: solid 1px #d4d4d4;
  margin-left: 5px; }

/* line 641, ../scss/master.scss */
table.message-list th.col-input {
  width: 25px; }

/* line 642, ../scss/master.scss */
table.message-list th.col-order {
  width: 25px;
  text-align: center; }

/* line 646, ../scss/master.scss */
table.message-list th.col-name {
  padding-left: 10px; }

/* line 648, ../scss/master.scss */
table.message-list th.col-date {
  width: 75px; }

/* line 649, ../scss/master.scss */
table.message-list th.col-status {
  width: 75px; }

/* line 650, ../scss/master.scss */
table.message-list th.col-extradate {
  width: 125px; }

/* line 651, ../scss/master.scss */
table.message-list th a {
  display: inline-block;
  line-height: 21px;
  padding-right: 25px; }

/* line 656, ../scss/master.scss */
table.message-list th a.sort {
  cursor: pointer; }

/* line 659, ../scss/master.scss */
table.message-list th a.sort-up,
table.message-list th a.sort-down {
  background-image: url(../Images/message-list-sort.gif);
  background-repeat: no-repeat; }

/* line 664, ../scss/master.scss */
table.message-list th a.sort-up {
  background-position: right 0; }

/* line 665, ../scss/master.scss */
table.message-list th a.sort-down {
  background-position: right -22px; }

/* line 666, ../scss/master.scss */
table.message-list td {
  border-bottom: 1px solid #f2f2f2;
  color: #505050;
  height: 20px;
  min-height: 20px;
  line-height: 1.3;
  padding-left: 5px;
  /*  was 10px; */
  padding-right: 5px; }

/* line 675, ../scss/master.scss */
table.message-list td.order {
  text-align: center; }

/* line 678, ../scss/master.scss */
table.message-list th:first-child,
table.message-list td:first-child {
  /* padding-left:20px; */ }

/* line 682, ../scss/master.scss */
table.message-list.pending tr td,
table.message-list tr.message-unread td {
  font-weight: bold; }

/* line 684, ../scss/master.scss */
table.message-list .attachment {
  background-repeat: no-repeat; }

/* CREATE NEW REQUEST FORM */
/* line 688, ../scss/master.scss */
.new-request select {
  /* width:280px; */
  padding: 3px;
  background-color: #FFF;
  /* border:solid 1px #7fb2dd; */
  margin-bottom: 5px;
  color: #333; }

/* line 696, ../scss/master.scss */
.new-request .select2-container {
  margin-bottom: 5px;
  margin-right: 5px; }

/* line 700, ../scss/master.scss */
.new-request .selected-item-add {
  margin: 10px 0;
  padding-top: 10px;
  position: relative;
  /* border-top: 1px solid #D4D4D4; */
  overflow: hidden; }

/* line 707, ../scss/master.scss */
.new-request .selected-item-add:after {
  content: "";
  display: block; }

/* line 711, ../scss/master.scss */
.new-request .selected-item-add .select-room-type {
  /* margin-top: 18px; */
  /* width:49.9%; */
  float: left; }

/* line 716, ../scss/master.scss */
.new-request .selected-item-add .select-room-type strong,
.new-request .selected-item-add .col2 strong {
  border-bottom: 1px solid #CCCCCC;
  clear: both;
  display: block;
  margin-bottom: 10px;
  padding-bottom: 4px; }

/* line 724, ../scss/master.scss */
.new-request .selected-item-add .select-room-type ul {
  list-style-type: none;
  /* margin-left: 70px; */
  /* margin-top: -19px; */ }

/* line 729, ../scss/master.scss */
.new-request .selected-item-add .select-room-type ul li {
  margin-left: 0;
  margin-bottom: 5px; }

/* line 730, ../scss/master.scss */
.new-request .selected-item-add .select-room-type ul li:after {
  content: "";
  clear: both;
  display: block; }

/* line 731, ../scss/master.scss */
.new-request .selected-item-add .select-room-type ul li label {
  display: block;
  padding-left: 20px; }

/* line 732, ../scss/master.scss */
.new-request .selected-item-add .select-room-type ul li input {
  float: left;
  margin: 0; }

/* line 733, ../scss/master.scss */
.new-request .selected-item-add .select-room-type ul li .roomtypeoccupancy {
  padding-left: 20px;
  margin-bottom: 0px; }

/* line 734, ../scss/master.scss */
.new-request .selected-item-add .select-room-type ul li .roomtypeoccupancy label {
  padding-left: 20px; }

/* line 735, ../scss/master.scss */
.new-request .selected-item-add .select-room-type span {
  margin-left: 5px; }

/* line 736, ../scss/master.scss */
.new-request .selected-item-add .select-dates {
  float: left;
  /* margin-left: -80px; */ }

/* line 740, ../scss/master.scss */
.new-request .selected-item-add .select-dates > label {
  line-height: 24px; }

/* line 741, ../scss/master.scss */
.new-request .selected-item-add .select-dates strong {
  border-bottom: 1px solid #CCCCCC;
  clear: both;
  display: block;
  float: none;
  margin-bottom: 10px;
  padding-bottom: 4px;
  /* margin-right: 30px; */ }

/* line 750, ../scss/master.scss */
.new-request .selected-item-add .select-dates input[type='text'] {
  /* margin-top: -5px; */
  /* margin-bottom: 7px; */ }

/* line 754, ../scss/master.scss */
.new-request .selected-item-add .form-buttons {
  text-align: left;
  border-top: 0 none; }

/* line 755, ../scss/master.scss */
.new-request .selected-items {
  margin: 10px 0 60px 0;
  padding-top: 10px;
  /* border-top: 1px solid #D4D4D4; */ }

/* line 760, ../scss/master.scss */
.new-request .selected-items th {
  white-space: nowrap;
  text-align: left; }

/* line 765, ../scss/master.scss */
.new-request .selected-items th,
.new-request .selected-items td {
  padding-right: 20px; }

/* line 769, ../scss/master.scss */
.new-request .selected-items td:last-child {
  padding-right: 0; }

/* line 772, ../scss/master.scss */
.new-request .selected-items td input.item-remove {
  background: none;
  height: auto;
  font-weight: normal;
  line-height: normal;
  color: #003399;
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: inline;
  min-height: 0; }

/* line 773, ../scss/master.scss */
.new-request .selected-items td input.item-remove:hover {
  text-decoration: underline; }

/* line 774, ../scss/master.scss */
.new-request input[type='button'] {
  width: 70px; }

/* line 775, ../scss/master.scss */
.new-request.change-allotment .selected-items table {
  width: 100%; }

/* line 776, ../scss/master.scss */
.new-request .selected-items table th.room-type,
.new-request .selected-items table th.room-type {
  /* width: 380px; */
  text-align: left; }

/* line 782, ../scss/master.scss */
.new-request.change-allotment .selected-items table th.date-from,
.new-request.change-allotment .selected-items table th.date-to,
.new-request.change-allotment .selected-items table th.amount {
  width: 80px; }

/* line 787, ../scss/master.scss */
.new-request.change-allotment .selected-items table th.button {
  width: 50px; }

/* line 790, ../scss/master.scss */
.new-request .selected-items td.duration,
.new-request .selected-items td.amount {
  text-align: left; }

/* line 794, ../scss/master.scss */
.new-request .minimum-stay-value {
  width: 50px; }

/* ADD NEW SALES REQUEST MULTIPLE */
/* line 799, ../scss/master.scss */
.request-multiple .new-request .column-one .select-room-type {
  margin-top: 5px; }

/* line 802, ../scss/master.scss */
.request-multiple .new-request .column-one .select-room-type li {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom-width: 0; }

/* line 807, ../scss/master.scss */
.request-multiple .new-request .column-two .select-dates {
  margin-bottom: 10px; }

/* line 810, ../scss/master.scss */
.request-multiple .new-request .form-buttons {
  border-bottom-width: 0;
  padding-bottom: 0; }

/* line 814, ../scss/master.scss */
.request-multiple .search-accommodations,
.request-multiple .selected-accommodations {
  border-bottom: solid 1px #d4d4d4;
  margin: 10px;
  margin-bottom: 1em;
  margin-right: 0; }

/* SUPPLIER LISTS - SELECT ACCOMMODATIONS SCREEN */
/* line 823, ../scss/master.scss */
.search-accommodations .search-header {
  border: 1px solid #e6e6e6;
  background-color: #d4d4d4;
  line-height: 32px;
  margin-bottom: 1em;
  padding-left: 10px;
  padding-right: 10px;
  position: relative; }

/* line 832, ../scss/master.scss */
.search-accommodations .search-header span {
  font-weight: bold; }

/* line 835, ../scss/master.scss */
.search-accommodations .search-header ol.paging {
  line-height: normal;
  position: absolute;
  right: 10px;
  top: 2px; }

/* line 841, ../scss/master.scss */
.search-accommodations .search-header .text-search {
  top: 4px;
  left: 395px; }

/* line 845, ../scss/master.scss */
.selected-accommodations:after {
  content: '';
  display: block;
  clear: both; }

/* line 850, ../scss/master.scss */
.selected-accommodations strong {
  display: block;
  margin-bottom: 1em; }

/* line 854, ../scss/master.scss */
.request-multiple ul.list-accommodations {
  color: #505050;
  line-height: 2em;
  list-style-type: none;
  margin-bottom: 2em;
  margin-left: -20px; }

/* line 861, ../scss/master.scss */
.request-multiple ul.list-accommodations li {
  border-bottom: 1px solid #f2f2f2;
  float: left;
  position: relative; }

/* line 866, ../scss/master.scss */
.request-multiple .search-accommodations ul.list-accommodations li {
  margin-left: 50px;
  margin-right: 0;
  width: 345px; }

/* line 871, ../scss/master.scss */
.request-multiple ul.list-accommodations li input {
  left: -30px;
  position: absolute;
  top: 1px; }

/* line 876, ../scss/master.scss */
.request-multiple ul.list-accommodations li label {
  cursor: pointer; }

/* line 879, ../scss/master.scss */
.request-multiple .selected-accommodations ul.list-accommodations li {
  margin-left: 20px;
  margin-right: 0;
  width: 375px; }

/* line 884, ../scss/master.scss */
.select-period-and-publication {
  border-top: 1px solid #D4D4D4;
  margin-top: 10px;
  padding-top: 10px; }

/* line 889, ../scss/master.scss */
.select-period-and-publication fieldset > label {
  display: inline-block;
  width: 75px;
  margin-right: 5px; }

/* line 894, ../scss/master.scss */
.select-period-and-publication fieldset > label[for="txtPeriodEndDate"] {
  width: auto;
  margin-left: 5px; }

/* line 898, ../scss/master.scss */
.select-period-and-publication .datepicker {
  width: 70px; }

/* line 901, ../scss/master.scss */
.select-period-and-publication select {
  width: 210px; }

/* line 905, ../scss/master.scss */
.select-period-airport-brand-pref fieldset > label {
  display: inline-block;
  width: 125px;
  margin-right: 5px; }

/* line 910, ../scss/master.scss */
.select-period-airport-brand-pref fieldset > label[for="txtPeriodEndDate"] {
  width: auto;
  margin-left: 5px; }

/* line 914, ../scss/master.scss */
.select-period-airport-brand-pref .datepicker {
  width: 70px; }

/* line 917, ../scss/master.scss */
.select-period-airport-brand-pref select {
  width: 210px; }

/* line 920, ../scss/master.scss */
.supplier-list {
  padding: 15px;
  padding-top: 0px;
  padding-left: 20px;
  overflow: hidden; }

/* line 927, ../scss/master.scss */
.supplier-list input[type="button"] {
  float: left; }

/* line 930, ../scss/master.scss */
.supplier-list .list-title {
  font-size: 1.5em;
  font-weight: bold;
  display: block; }

/* line 935, ../scss/master.scss */
.supplier-list ol.top-links {
  border-top: 2px solid #000000;
  list-style-type: none;
  padding-top: 10px; }

/* line 940, ../scss/master.scss */
.supplier-list ol.top-links li {
  margin-left: 0px; }

/* line 943, ../scss/master.scss */
.supplier-list .navigation-buttons {
  margin-top: 15px; }

/* line 946, ../scss/master.scss */
.supplier-list .error {
  clear: both;
  padding-left: 5px;
  font-weight: bold; }

/* line 952, ../scss/master.scss */
.supplier-list ul.legend {
  list-style-type: none;
  font-style: italic; }

/* line 956, ../scss/master.scss */
.search-accommodations-container .search-buttons {
  overflow: hidden; }

/* line 959, ../scss/master.scss */
.search-accommodations-container > fieldset {
  margin-bottom: 10px; }

/* line 962, ../scss/master.scss */
.search-accommodations-container > fieldset label {
  display: inline-block;
  width: 75px;
  margin-right: 5px; }

/* line 968, ../scss/master.scss */
.search-accommodations-container > fieldset input {
  width: 180px; }

/* line 971, ../scss/master.scss */
.search-accommodations-container > fieldset legend {
  position: absolute;
  left: -9999px;
  height: 0;
  width: 0;
  overflow: hidden; }

/* line 974, ../scss/master.scss */
.search-accommodations-container span.no-accommodations {
  padding-left: 5px; }

/* line 977, ../scss/master.scss */
.search-accommodations-container .selected-accommodations {
  clear: both;
  margin-top: 10px; }

/* line 982, ../scss/master.scss */
.search-accommodations-container .selected-accommodations td.action-icon {
  background: url(../images/acco-remove.gif) no-repeat center center; }

/* line 985, ../scss/master.scss */
.accommodations-list-header {
  margin-top: 15px;
  padding: 5px;
  font-weight: bold; }

/* line 991, ../scss/master.scss */
.accommodations-list-header .total-count {
  padding-left: 10px; }

/* line 994, ../scss/master.scss */
.accommodations-list-header .all-action {
  float: right;
  text-align: center;
  width: 100px;
  cursor: pointer; }

/* line 1000, ../scss/master.scss */
.accommodations-list-header .separator {
  font-weight: normal;
  float: right; }

/* line 1005, ../scss/master.scss */
table.accommodations-list {
  width: 100%; }

/* line 1008, ../scss/master.scss */
table.accommodations-list tr:hover {
  background-color: #E6F0F8; }

/* line 1011, ../scss/master.scss */
table.accommodations-list th, table.accommodations-list td {
  border: 2px solid #FFF;
  padding: 2px; }

/* line 1015, ../scss/master.scss */
table.accommodations-list th {
  padding-top: 4px;
  background-color: #7FB2DD;
  color: #FFF; }

/* line 1021, ../scss/master.scss */
table.accommodations-list .action-icon {
  width: 18px;
  padding: 0px;
  cursor: pointer; }

/* line 1027, ../scss/master.scss */
.available-accommodations:after {
  content: '';
  display: block;
  clear: both; }

/* line 1032, ../scss/master.scss */
.available-accommodations td.action-icon {
  background: url(../images/acco-add.gif) no-repeat center center; }

/* Styles for the Supplier Portal - Transferlist */
/* line 1036, ../scss/master.scss */
.transfer-result-container {
  width: 100%; }

/* line 1039, ../scss/master.scss */
.transfer-result-container ol.top-links {
  margin-top: 5px; }

/* line 1042, ../scss/master.scss */
.transfer-result-container .publication-header {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  font-weight: bold; }

/* line 1050, ../scss/master.scss */
.transfer-result-container .report-item {
  margin-top: 15px;
  margin-bottom: 30px; }

/* line 1054, ../scss/master.scss */
.transfer-result-container .report-item .report-header {
  width: 100%;
  font-size: 1.1em;
  border-bottom: 1px solid #000;
  margin-bottom: 10px; }

/* line 1061, ../scss/master.scss */
.transfer-result-container .report-item .report-header .departure-date {
  width: 250px; }

/* line 1065, ../scss/master.scss */
.transfer-result-container .report-item .report-header .departure-airport {
  width: 250px; }

/* line 1069, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container {
  width: 100%; }

/* line 1072, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container .booking-info-row {
  vertical-align: top; }

/* line 1075, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container .booking-info-header td {
  border-bottom: 1px solid #000; }

/* line 1078, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container .booking-info-header .column-booking {
  width: 30px; }

/* line 1081, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container .booking-info-header .column-s {
  width: 15px; }

/* line 1084, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container .booking-info-header .column-name {
  width: 125px; }

/* line 1087, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container .booking-info-header .dateofbirth {
  width: 60px; }

/* line 1090, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container .booking-info-header .column-seat {
  width: 15px; }

/* line 1093, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container .booking-info-header .column-duration {
  width: 60px; }

/* line 1096, ../scss/master.scss */
.transfer-result-container .report-item .bookings-container .booking-info-header .column-remarks {
  width: 300px; }

/* line 1099, ../scss/master.scss */
.transfer-result-container .totals {
  border-top: 1px solid #000;
  border-bottom: 2px solid #000; }

/* line 1104, ../scss/master.scss */
.transfer-result-container .accommodation-totals {
  border-bottom: 1px solid #000; }

/* line 1108, ../scss/master.scss */
.transfer-result-container .pax-column {
  display: inline-block;
  width: 50%; }

/* line 1113, ../scss/master.scss */
.transfer-result-container .flight-total {
  border-bottom: 2px solid #000; }

/* line 1117, ../scss/master.scss */
.transfer-result-container .accommodationheader {
  margin-bottom: 7px; }

/* line 1120, ../scss/master.scss */
.transfer-result-container .accommodationheader .destination {
  min-width: 210px;
  float: right; }

/* line 1125, ../scss/master.scss */
.transfer-list-main-booker {
  font-weight: bold; }

/* line 1128, ../scss/master.scss */
.transfer-result-container .list-total {
  width: 100%;
  margin-top: 20px; }

/* line 1133, ../scss/master.scss */
.transfer-result-container .list-total .total-column {
  width: 700px; }

/* line 1137, ../scss/master.scss */
.transfer-result-container .list-total th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left; }

/* End of Styles for Transferlist */
/* Styles for the Supplier Portal - RoomingList */
/* line 1145, ../scss/master.scss */
.rooming-list-header {
  font-size: 11px;
  line-height: normal;
  border-top: solid 1px #7fb2dd;
  font-style: italic; }

/* line 1146, ../scss/master.scss */
.rooming-list-header tr:first-child td {
  padding-top: 5px; }

/* line 1147, ../scss/master.scss */
.rooming-list-header tr td:first-child + td {
  font-weight: bold;
  font-style: normal;
  padding-right: 0;
  font-size: 12px; }

/* line 1148, ../scss/master.scss */
.rooming-list-listname {
  text-align: right; }

/* line 1151, ../scss/master.scss */
.rooming-list-sundio {
  text-align: left; }

/* line 1154, ../scss/master.scss */
.rooming-list {
  text-align: left; }

/* line 1155, ../scss/master.scss */
.rooming-list .user-information {
  padding-bottom: 5px; }

/* line 1156, ../scss/master.scss */
.rooming-list .user-preference {
  font-style: italic;
  font-size: 11px;
  line-height: 20px; }

/* line 1157, ../scss/master.scss */
.rooming-list-title {
  text-align: right; }

/* line 1160, ../scss/master.scss */
.rooming-list td {
  vertical-align: top; }

/* line 1163, ../scss/master.scss */
table.message-list th.checkbox-column {
  width: 20px;
  padding-right: 20px; }

/* line 1167, ../scss/master.scss */
table.legend {
  float: left;
  margin-right: 40px;
  font-size: 11px;
  line-height: normal; }

/* line 1168, ../scss/master.scss */
.rooming-list-message .details-item:after {
  content: "";
  display: block;
  clear: both; }

/* End of Styles for RoomingList */
/* line 1170, ../scss/master.scss */
.accommodation-result-container .report-item {
  margin-top: 15px;
  margin-bottom: 30px; }

/* line 1175, ../scss/master.scss */
.accommodation-result-container .report-item .report-header {
  width: 100%;
  font-size: 1.1em;
  border-bottom: 1px solid #000; }

/* line 1181, ../scss/master.scss */
.accommodation-result-container .report-item .report-header tr:first-child {
  font-weight: bold; }

/* line 1184, ../scss/master.scss */
.accommodation-result-container .report-item .bookings-container {
  border-bottom: 1px solid #000;
  width: 100%; }

/* line 1188, ../scss/master.scss */
.accommodation-result-container .report-item .bookings-container .booking-info-row:first-child td {
  padding-top: 5px; }

/* line 1191, ../scss/master.scss */
.accommodation-result-container .report-item .bookings-container .booking-info-row td {
  padding-top: 20px; }

/* line 1194, ../scss/master.scss */
.accommodation-result-container .report-item .bookings-container .totals {
  font-style: italic;
  font-weight: bold;
  border-bottom: 2px solid #000;
  border-top: 1px solid #000; }

/* line 1201, ../scss/master.scss */
.accommodation-result-container .report-item td {
  vertical-align: top; }

/* LOGIN SCREEN */
/* line 1206, ../scss/master.scss */
.section.login {
  width: 980px; }

/* line 1207, ../scss/master.scss */
.login .main-content {
  margin-top: 100px; }

/* line 1208, ../scss/master.scss */
.login .login-header {
  background-color: #00468C;
  border: 1px solid #00468C;
  color: #FFFFFF;
  height: 20px;
  line-height: 20px;
  margin-bottom: 5px;
  padding: 10px;
  width: 780px; }

/* line 1218, ../scss/master.scss */
.login .login-header span {
  float: right;
  font-size: 11px;
  font-weight: bold; }

/* line 1219, ../scss/master.scss */
.login .login-header span:first-child {
  float: left;
  font-size: 12px; }

/* line 1220, ../scss/master.scss */
.login .login-container {
  background-color: #F9F9F9;
  background-position: 490px center;
  background-repeat: no-repeat;
  border: 1px solid #C9C9C9;
  margin: 0;
  padding: 70px;
  padding-left: 90px;
  width: 640px; }

/* line 1230, ../scss/master.scss */
.login .login-container fieldset {
  width: 310px;
  margin-bottom: 10px; }

/* line 1231, ../scss/master.scss */
.login .login-container label {
  float: left;
  line-height: 22px;
  width: 50px; }

/* line 1232, ../scss/master.scss */
.login .login-container input {
  background-color: #FFFFFF;
  border: 1px solid #7FB2DD;
  color: #333333;
  margin-bottom: 5px;
  padding: 3px;
  width: 230px;
  float: right; }

/* line 1241, ../scss/master.scss */
.login .login-container .login-bttn {
  background-color: #181818;
  color: #fff;
  border: 0;
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 30px;
  width: 150px;
  margin-top: 3px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

/* line 1255, ../scss/master.scss */
.login .login-container a {
  display: block;
  text-align: right;
  width: 310px;
  font-size: 11px;
  line-height: 18px; }

/* line 1262, ../scss/master.scss */
.login {
  margin: 25px; }

/* line 1265, ../scss/master.scss */
.footer.login {
  width: 780px; }

/* line 1266, ../scss/master.scss */
div.log {
  float: right;
  width: 150px;
  margin-top: 20px; }

/* line 1271, ../scss/master.scss */
div.log a.logout, .change-password {
  text-align: right; }

/* line 1274, ../scss/master.scss */
.change-password {
  font-weight: normal; }

/* line 1277, ../scss/master.scss */
.dl-change-password dt {
  float: left;
  margin-right: 10px;
  width: 120px; }

/* line 1282, ../scss/master.scss */
.secondary-actions ul.list-actions li ul li .select2-container {
  height: 18px; }

/* line 1285, ../scss/master.scss */
.secondary-actions ul.list-actions li ul li .select2-container .select2-choice {
  color: #000;
  height: 22px;
  position: absolute; }

/* line 1290, ../scss/master.scss */
.secondary-actions ul.list-actions li ul li .select2-container .select2-results {
  background-color: #fff;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  border: 0;
  top: 0; }

/* line 1299, ../scss/master.scss */
.secondary-actions ul.list-actions li ul li .select2-container .select2-results li {
  color: #333; }

/* line 1302, ../scss/master.scss */
.selected-item-add {
  min-height: 100px;
  display: block; }

/* line 1306, ../scss/master.scss */
.new-request .discount,
.new-request .conditions {
  width: 50%;
  float: left; }

/* line 1311, ../scss/master.scss */
.entry-line {
  width: 100%;
  padding-bottom: 10px; }

/* line 1315, ../scss/master.scss */
.entry-line:after {
  content: "";
  clear: both;
  display: block; }

/* line 1316, ../scss/master.scss */
.btn-add-item {
  position: inherit; }

/* line 1320, ../scss/master.scss */
.new-request .entry-line label:first-child {
  font-weight: bold;
  vertical-align: middle; }

/* line 1324, ../scss/master.scss */
.new-request .discount-amount-type {
  width: 100px;
  margin-right: 0; }

/* line 1328, ../scss/master.scss */
.new-request .conditions {
  position: relative;
  height: 200px; }

/* line 1332, ../scss/master.scss */
.new-request .conditions input[type=button] {
  right: 0;
  bottom: 0; }

/* line 1336, ../scss/master.scss */
.new-request .conditions label:first-child {
  width: 125px;
  display: inline-block; }

/* line 1340, ../scss/master.scss */
.new-request .selected-item-add .select-releases div {
  position: relative;
  width: 400px;
  line-height: 2em; }

/* line 1345, ../scss/master.scss */
.new-request .selected-item-add .select-releases div input {
  position: absolute;
  left: 160px; }

/* line 1349, ../scss/master.scss */
.new-request .selected-item-add .select-dates label {
  padding-right: 5px; }

/* line 1350, ../scss/master.scss */
.new-request .selected-item-add .select-dates input.hasDatepicker {
  width: 64px; }

/* line 1351, ../scss/master.scss */
.new-request .selected-item-add .select-dates #txtFromDate {
  margin-right: 10px; }

/* line 1352, ../scss/master.scss */
.new-request .selected-item-add .select-dates #txtUntilDate {
  margin-right: 10px; }

/* line 1353, ../scss/master.scss */
.new-request.release .selected-item-add .select-dates input {
  /* margin-top: -4px; */ }

/* line 1356, ../scss/master.scss */
.btn-add-item {
  position: relative; }

/* line 1360, ../scss/master.scss */
.validation-errors {
  background: red;
  color: #FFFFFF;
  font-weight: bold;
  opacity: 0.7;
  margin-bottom: 10px; }

/* line 1367, ../scss/master.scss */
.validation-errors ul {
  margin: 0 5px 10px 5px; }

/* line 1368, ../scss/master.scss */
.validation-errors li {
  list-style-type: none;
  margin: 0;
  line-height: 20px; }

/* line 1369, ../scss/master.scss */
.success-message {
  background: green;
  color: #FFFFFF;
  font-weight: bold;
  opacity: 0.7;
  margin-bottom: 10px; }

/* line 1376, ../scss/master.scss */
.new-request .selected-item-add .select-releases {
  margin: 20px 0; }

/* line 1377, ../scss/master.scss */
.new-request .selected-item-add .select-releases > strong {
  border-bottom: 1px solid #CCCCCC;
  clear: both;
  display: block;
  margin-bottom: 10px;
  padding-bottom: 4px; }

/* line 1385, ../scss/master.scss */
.new-request .selected-item-add input[type='button'] {
  /* margin-top: -6px; */
  width: 70px; }

/* line 1389, ../scss/master.scss */
.new-request .selected-item-add .select-releases input {
  width: 64px;
  margin-right: 10px; }

/* line 1390, ../scss/master.scss */
.new-request.change-allotment .selected-item-add label {
  /* width: 67px; */ }

/* line 1393, ../scss/master.scss */
.new-request.change-allotment .selected-item-add .amount-inputs {
  clear: both;
  width: 100%;
  padding-top: 10px; }

/* line 1394, ../scss/master.scss */
.new-request.change-allotment .selected-item-add .amount-inputs label.amount {
  font-weight: bold;
  /* margin-right:32px; */ }

/* line 1395, ../scss/master.scss */
.new-request.change-allotment .select-dates input[type='button'] {
  /* margin-top: -4px; */ }

/* line 1398, ../scss/master.scss */
.new-request .discount .select-room-type {
  width: 100%; }

/* line 1401, ../scss/master.scss */
.new-request .discount-info .valid-once input {
  margin-left: 0;
  margin-right: 10px;
  float: left; }

/* line 1402, ../scss/master.scss */
.new-request .discount-info .valid-once .explanation {
  display: inline; }

/* Booking details */
/* line 1404, ../scss/master.scss */
.booking-details {
  list-style-type: none;
  padding: 0;
  margin: 0; }

/* line 1405, ../scss/master.scss */
.booking-details li {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 0px; }

/* line 1406, ../scss/master.scss */
.booking-details li h3 {
  color: #004E90;
  font-size: 12px;
  margin-bottom: 10px;
  border-bottom: 2px solid #E6E6E6; }

/* line 1412, ../scss/master.scss */
.booking-details .booking-information {
  clear: none;
  height: 55px;
  background-color: #eee;
  margin-top: 12px;
  padding: 10px 10px 0;
  border-bottom: solid 2px #adf; }

/* line 1420, ../scss/master.scss */
.booking-details .booking-information:after {
  content: "";
  display: block;
  height: 0;
  clear: both; }

/* line 1426, ../scss/master.scss */
.booking-details .booking-information dt {
  min-width: 40%; }

/* line 1427, ../scss/master.scss */
.booking-details .booking-information dl {
  width: 50%;
  overflow: hidden;
  float: left; }

/* line 1432, ../scss/master.scss */
.booking-details .booking-information dl * {
  float: left;
  margin-right: 10px;
  font-size: 14px;
  line-height: normal; }

/* line 1438, ../scss/master.scss */
.booking-details .booking-information dl dd {
  margin-right: 0;
  font-weight: bold;
  max-width: 50%; }

/* line 1443, ../scss/master.scss */
.booking-details table {
  width: 100%; }

/* line 1446, ../scss/master.scss */
.booking-details .booking-part {
  margin-top: 30px; }

/* line 1449, ../scss/master.scss */
.booking-details table th {
  /*background-color: #adf;
   font-weight: normal; */ }

/* line 1453, ../scss/master.scss */
.booking-details .travelers,
.booking-details .preferences,
.booking-details .services,
.booking-details .coach-information {
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden; }

/* line 1461, ../scss/master.scss */
.booking-details .travelers th,
.booking-details .preferences th,
.booking-details .services th,
.booking-details .coach-information th {
  text-align: left; }

/* line 1467, ../scss/master.scss */
ul.lists-overview {
  padding: 15px; }

/* line 1470, ../scss/master.scss */
ul.lists-overview li {
  margin-bottom: 10px; }

/* line 1473, ../scss/master.scss */
div.error,
span.error {
  color: #f00; }

/* line 1477, ../scss/master.scss */
input.error {
  border-color: #f00; }

/* line 1480, ../scss/master.scss */
tr.cancelled td {
  color: #f00; }

/* line 1483, ../scss/master.scss */
table.bookings {
  width: 100%; }

/* line 1486, ../scss/master.scss */
table.bookings .booking-number {
  width: 120px; }

/* line 1489, ../scss/master.scss */
table.bookings .is-cancelled {
  width: 80px; }

/* line 1492, ../scss/master.scss */
table.bookings .publication-code {
  width: 50px; }

/* line 1495, ../scss/master.scss */
table.bookings .transportation-code {
  width: 50px; }

/* line 1498, ../scss/master.scss */
table.bookings .departure-date {
  width: 110px; }

/* View Bookings screen */
/* line 1502, ../scss/master.scss */
.bookings-search p.intro {
  padding: 0 10px;
  margin-top: 20px; }

/* line 1503, ../scss/master.scss */
.bookings-search .search-form {
  padding: 20px 10px 20px 10px;
  border-bottom: solid 0px #dedede;
  margin-bottom: 20px; }

/* line 1504, ../scss/master.scss */
.bookings-search .search-form:after {
  content: "";
  display: block;
  clear: both; }

/* line 1505, ../scss/master.scss */
.bookings-search .search-form .col {
  float: left; }

/* line 1506, ../scss/master.scss */
.bookings-search .search-form .col:first-child {
  margin-right: 10px; }

/* line 1507, ../scss/master.scss */
.bookings-search .search-form legend {
  text-indent: -999em;
  height: 0;
  position: absolute; }

/* line 1508, ../scss/master.scss */
.bookings-search .search-form label {
  display: inline-block;
  min-width: 160px;
  margin-right: 10px;
  font-weight: bold; }

/* line 1509, ../scss/master.scss */
.bookings-search .search-form input[type='text'] {
  display: inline-block;
  min-width: 200px;
  height: 18px; }

/* line 1510, ../scss/master.scss */
.bookings-search .search-form .clear {
  display: block;
  clear: both; }

/* line 1511, ../scss/master.scss */
.bookings-search .form-buttons {
  text-align: right;
  padding-top: 3px;
  clear: none;
  border: none;
  margin-left: 20px; }

/* line 1512, ../scss/master.scss */
.bookings-search .form-buttons input {
  display: inline-block;
  text-align: center;
  float: none;
  margin-left: 10px; }

/* line 1513, ../scss/master.scss */
.bookings-search .search-form .error {
  clear: both;
  display: block; }

/* line 1514, ../scss/master.scss */
.new-request.facts .selected-item-add,
.new-request.facts .selected-item-add .select-room-type {
  border: 0;
  margin: 0;
  min-height: 0; }

/* line 1520, ../scss/master.scss */
.facts-edit {
  border-top: 1px solid #D4D4D4; }

/* line 1523, ../scss/master.scss */
.facts-edit input[type="checkbox"] {
  vertical-align: bottom; }

/* line 1526, ../scss/master.scss */
.facts-differences-new-request {
  padding: 10px; }

/* line 1529, ../scss/master.scss */
.facts-differences {
  padding-top: 10px; }

/* line 1532, ../scss/master.scss */
.facts-differences table, .facts-differences th, .facts-differences td {
  padding-left: 10px;
  border-collapse: collapse;
  border: 1px solid black; }

/* line 1537, ../scss/master.scss */
.facts-differences table {
  width: 100%; }

/* line 1540, ../scss/master.scss */
.facts-differences td {
  width: 50%; }

/* line 1543, ../scss/master.scss */
.facts-differences .subtitle {
  background-color: lightgrey; }

/* line 1546, ../scss/master.scss */
.facts-differences .inserted {
  background-color: lightgreen; }

/* line 1549, ../scss/master.scss */
.facts-differences .deleted {
  background-color: lightcoral; }

/* line 1552, ../scss/master.scss */
.facts-differences .updated {
  background-color: lightsalmon; }

/* line 1555, ../scss/master.scss */
ul.fact-list {
  list-style: none;
  margin-bottom: 0; }

/* line 1559, ../scss/master.scss */
.fact ul.fact-list {
  display: none; }

/* line 1562, ../scss/master.scss */
.fact.expanded ul.fact-list {
  display: block; }

/* line 1565, ../scss/master.scss */
.fact {
  position: relative; }

/* line 1568, ../scss/master.scss */
.fact span {
  margin-left: -15px; }

/* line 1571, ../scss/master.scss */
.fact .expand_button {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 1px;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat; }

/* line 1580, ../scss/master.scss */
.fact.expanded .expand_button,
.fact.collapsed .fact.expanded .expand_button {
  background-image: url("../images/minus.png"); }

/* line 1584, ../scss/master.scss */
.fact.collapsed .expand_button {
  background-image: url("../images/plus.png"); }

/* line 1587, ../scss/master.scss */
.fact span.fact-changed {
  color: #c5df26;
  margin-left: 0px; }

/* line 1591, ../scss/master.scss */
.fact-parameters {
  margin-left: 23px; }

/* line 1594, ../scss/master.scss */
.fact-parameters ul {
  padding-left: 15px;
  margin: 0; }

/* line 1598, ../scss/master.scss */
.fact-parameters .validation-errors ul {
  padding-top: 0; }

/* line 1601, ../scss/master.scss */
.fact-parameters li.parameter {
  list-style: none;
  margin: 0; }

/* line 1606, ../scss/master.scss */
.fact-parameters .parameter span {
  cursor: pointer; }

/* line 1609, ../scss/master.scss */
.fact-parameters .parameter span:hover {
  text-decoration: underline; }

/* line 1612, ../scss/master.scss */
.fact-parameters input[type="submit"] {
  margin-left: 5px; }

/* line 1615, ../scss/master.scss */
.select-global {
  margin-bottom: 20px; }

/* line 1616, ../scss/master.scss */
.select-global:after {
  clear: both;
  content: "";
  display: block; }

/* line 1617, ../scss/master.scss */
.select-global .select2-container {
  margin-left: 0;
  margin-right: 0;
  width: 49.5% !important;
  float: left; }

/* line 1623, ../scss/master.scss */
.add-item-button {
  float: right;
  margin-right: 245px !important; }

/*
	This section contains adjustments to layout of the screens and its temporary!
	All these selectors will have to be moved in their respective location, above in CSS and
	all the overwritten CSS properties will be deleted.
	The specific selectors could be replaced with new CSS classes, which will be added in HTML pages.
 */
/* line 1633, ../scss/master.scss */
.select-global .global-select-resort {
  margin-left: 1%; }

/* 2 column layout in 'add-new-' type of screens */
/* line 1635, ../scss/master.scss */
.new-request .selected-item-add .select-room-type,
.new-request.special-offer .selected-item-add .discount {
  width: 49.5% !important;
  margin-right: 1% !important; }

/* line 1639, ../scss/master.scss */
.new-request .selected-item-add .select-dates,
.new-request.special-offer .selected-item-add .conditions,
.new-request.special-offer .entry-line .col2 {
  width: 49.5% !important;
  margin-right: 0 !important; }

/* line 1644, ../scss/master.scss */
.new-request.special-offer .entry-line {
  margin-bottom: 3px; }

/* line 1645, ../scss/master.scss */
.new-request.special-offer .entry-line label:first-child {
  display: inline-block; }

/* line 1646, ../scss/master.scss */
.new-request.special-offer .selected-item-add .conditions input,
.new-request.special-offer .selected-item-add .conditions label {
  margin-left: 5px; }

/* line 1648, ../scss/master.scss */
.new-request.special-offer .selected-item-add .conditions label:first-child {
  margin-left: 0; }

/* line 1649, ../scss/master.scss */
.new-request.special-offer .entry-line #txtDiscountAmount {
  margin-left: 5px;
  margin-right: 5px;
  width: 19%;
  /* width:50px; */ }

/* line 1650, ../scss/master.scss */
.new-request.special-offer .entry-line #txtDiscountAmount + span + span + span {
  line-height: 30px; }

/* line 1651, ../scss/master.scss */
.new-request.special-offer .entry-line .col2 {
  float: left; }

/* line 1652, ../scss/master.scss */
.new-request.special-offer .entry-line .col2 > div {
  margin-bottom: 20px; }

/* line 1653, ../scss/master.scss */
.new-request.special-offer .entry-line .discount-info select {
  width: 70%; }

/* line 1655, ../scss/master.scss */
.new-request.special-offer .selected-item-add .conditions .entry-line#discountWeekdaysSection input {
  margin: 0px; }

/* line 1656, ../scss/master.scss */
.select2-container .select2-results .select2-highlighted {
  background-color: #004E90; }

/* line 1657, ../scss/master.scss */
.section.request-bookings .details-item label {
  display: inline-block;
  width: 140px; }

/* Arrangement list */
/* line 1662, ../scss/master.scss */
.arrangement-search {
  padding: 15px;
  padding-top: 0px;
  margin-left: 5px;
  overflow: hidden; }

/* line 1668, ../scss/master.scss */
.arrangement-search input[type="button"] {
  clear: both;
  float: left;
  margin-top: 15px;
  display: block; }

/* line 1675, ../scss/master.scss */
.arrangement-search h4 {
  margin-bottom: 10px; }

/* line 1678, ../scss/master.scss */
.arrangement-search div.error {
  clear: both; }

/* line 1681, ../scss/master.scss */
.arrangements-container {
  width: 35%;
  float: left; }

/* line 1686, ../scss/master.scss */
.arrangements-container table,
.arrangements-container table tr th,
.arrangements-container table tr td {
  border-collapse: separate; }

/* line 1691, ../scss/master.scss */
.arrangements-container table tr td label {
  padding-left: 5px; }

/* line 1694, ../scss/master.scss */
.arrangements-container table tr td input[type="radio"] {
  margin: 0; }

/* line 1697, ../scss/master.scss */
.arrangements-container ol.paging {
  float: left;
  margin-top: 10px;
  margin-bottom: 10px; }

/* line 1702, ../scss/master.scss */
.arrangements-container .selected-arrangement {
  clear: both;
  margin-top: 10px;
  margin-bottom: 10px; }

/* line 1707, ../scss/master.scss */
.arrangements-container .selected-arrangement span {
  margin-left: 5px; }

/* line 1710, ../scss/master.scss */
.arrangement-period .datepicker {
  width: 70px; }

/* line 1713, ../scss/master.scss */
.arrangement-results {
  padding: 15px; }

/* line 1716, ../scss/master.scss */
.arrangement-results table {
  padding-top: 5px;
  padding-bottom: 5px;
  border-collapse: separate; }

/* line 1721, ../scss/master.scss */
.arrangement-results table tr td {
  border-collapse: separate; }

/* line 1725, ../scss/master.scss */
.arrangement-results .navigation-buttons {
  margin-top: 15px; }

/* line 1728, ../scss/master.scss */
.arrangement-results table.trademarks-and-info {
  width: 100%;
  border-top: 1px solid #A9A9A9;
  border-bottom: 1px solid #A9A9A9;
  margin-bottom: 20px; }

/* line 1734, ../scss/master.scss */
.arrangement-results table.trademarks-and-info tr:first-child {
  font-weight: bold; }

/* line 1737, ../scss/master.scss */
.arrangement-results table.trademarks-and-info tr:first-child td:last-child {
  text-align: right; }

/* line 1740, ../scss/master.scss */
.arrangement-results table.arrangement-info {
  width: 100%;
  border-top: 1px solid #A9A9A9;
  border-bottom: 1px solid #A9A9A9;
  margin-top: 15px; }

/* line 1746, ../scss/master.scss */
.arrangement-results table.arrangement-bookings {
  border-bottom: 1px solid #A9A9A9;
  border-collapse: collapse;
  width: 100%; }

/* line 1751, ../scss/master.scss */
.arrangement-results table.arrangement-bookings tr.column-names {
  border-collapse: collapse; }

/* line 1754, ../scss/master.scss */
.arrangement-results table.arrangement-bookings tr.column-names td {
  border-collapse: collapse;
  border-bottom: 1px solid #A9A9A9;
  padding: 20px 2px; }

/* line 1760, ../scss/master.scss */
.arrangement-results table.arrangement-bookings tr.booking-info {
  border-collapse: separate; }

/* line 1763, ../scss/master.scss */
.arrangement-results table.arrangement-bookings tr.booking-info td {
  padding: 2px; }

/* line 1766, ../scss/master.scss */
.arrangement-results table.arrangement-bookings tr.separator td {
  height: 7px; }

/* line 1769, ../scss/master.scss */
.arrangement-results table.publications {
  margin-top: 20px; }

/* line 1772, ../scss/master.scss */
.arrangement-results div.total {
  margin-top: 10px; }

/* line 1775, ../scss/master.scss */
.arrangement-results div.total span {
  margin-left: 100px; }

/* line 1778, ../scss/master.scss */
.arrangement-results .pdf-download {
  display: inline-block;
  margin-bottom: 15px; }

/* line 1782, ../scss/master.scss */
.pdf-download {
  cursor: pointer; }

/* line 1785, ../scss/master.scss */
.pdf-download .pdf-icon {
  background: url(../images/pdficon_small.png) no-repeat left center;
  display: block;
  float: left;
  height: 16px;
  cursor: pointer;
  width: 16px;
  padding-right: 5px; }

/* line 1795, ../scss/master.scss */
#ajaxLoadingDialog {
  margin: 15px;
  margin-top: 40px;
  display: none; }

/* Allotment overview (tree) */
/* line 1802, ../scss/master.scss */
.accommodation-filters {
  padding-left: 15px; }

/* line 1805, ../scss/master.scss */
.accommodation-filters input[type="checkbox"] {
  vertical-align: bottom; }

/* line 1808, ../scss/master.scss */
.node {
  position: relative; }

/* line 1811, ../scss/master.scss */
.node span {
  margin-left: -15px; }

/* line 1814, ../scss/master.scss */
.node .expand_button {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 1px;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat; }

/* line 1823, ../scss/master.scss */
.node.expanded ul {
  display: block; }

/* line 1826, ../scss/master.scss */
.node.expanded .expand_button {
  background-image: url("../images/minus.png"); }

/* line 1829, ../scss/master.scss */
.node.collapsed ul {
  display: none; }

/* line 1832, ../scss/master.scss */
.node.collapsed .expand_button {
  background-image: url("../images/plus.png"); }

/* Invoice overview */
/* line 1836, ../scss/master.scss */
.types-filter, .status-filter {
  min-width: 200px;
  width: 49%;
  float: left;
  margin-right: 1%;
  min-height: 250px; }
  @media (min-width: 1100px) {
    /* line 1836, ../scss/master.scss */
    .types-filter, .status-filter {
      width: 29%; } }
  /* line 1842, ../scss/master.scss */
  .types-filter > strong, .status-filter > strong {
    display: block;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 4px; }
  /* line 1851, ../scss/master.scss */
  .types-filter ul, .status-filter ul {
    list-style: none;
    padding-left: 0; }

/* line 1856, ../scss/master.scss */
.date-filter {
  min-width: 300px;
  width: 400px; }
  @media (min-width: 1100px) {
    /* line 1856, ../scss/master.scss */
    .date-filter {
      width: auto; } }
  /* line 1862, ../scss/master.scss */
  .date-filter > strong {
    display: inline-block;
    vertical-align: top;
    min-height: 40px;
    min-width: 100px;
    width: 10%; }
  /* line 1869, ../scss/master.scss */
  .date-filter label {
    margin-right: 0.5%; }
  /* line 1872, ../scss/master.scss */
  .date-filter input {
    margin-right: 5px; }

/* line 1878, ../scss/master.scss */
table.message-list tr.row-selected {
  background: #d0e2f2; }
/* line 1883, ../scss/master.scss */
table.message-list tr td span.negative {
  color: red; }
/* line 1887, ../scss/master.scss */
table.message-list tr td input[type="button"] {
  background-repeat: no-repeat;
  background-color: transparent;
  width: 16px;
  height: 16px; }
  /* line 1892, ../scss/master.scss */
  table.message-list tr td input[type="button"].HTML {
    background-image: url(../images/email.png); }
  /* line 1895, ../scss/master.scss */
  table.message-list tr td input[type="button"].PDF {
    background-image: url(../images/invoice.png); }
  /* line 1898, ../scss/master.scss */
  table.message-list tr td input[type="button"].PDF_ATTACHED {
    background-image: url(../images/invoice.png); }
  /* line 1901, ../scss/master.scss */
  table.message-list tr td input[type="button"].payment-spec {
    background-image: url(../images/payment-spec.png); }
/* line 1907, ../scss/master.scss */
table.message-list + table {
  width: 100%; }
  /* line 1910, ../scss/master.scss */
  table.message-list + table th:first-child {
    width: 15%;
    padding-left: 2.35%; }
  /* line 1914, ../scss/master.scss */
  table.message-list + table th:nth-child(2) {
    width: 35%;
    padding-left: 1.5%; }
  /* line 1918, ../scss/master.scss */
  table.message-list + table th:nth-child(3) {
    width: 50%;
    text-align: right; }

/* Splash page */
/* line 1926, ../scss/master.scss */
.splash {
  box-sizing: border-box;
  background: #f4f7fc;
  padding: 57px 66px;
  width: 1024px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  vertical-align: top; }

/* line 1942, ../scss/master.scss */
.splash__option {
  display: inline-block;
  margin-bottom: 45px;
  background: #004e90;
  color: #fff;
  padding: 25px 25px;
  border-radius: 6px;
  font-size: 18px;
  border: 1px solid #014f91;
  width: 410px;
  height: 70px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  transition: background 0.35s ease; }

/* line 1958, ../scss/master.scss */
.splash__option:nth-child(odd) {
  margin-right: 55px; }

/* line 1961, ../scss/master.scss */
.splash__option:hover {
  background: #7fb2dd;
  color: #ffffff;
  text-decoration: none; }

/* line 1966, ../scss/master.scss */
.splash__option:active {
  background: #7fb2dd; }

/* Super tabs */
/* line 1970, ../scss/master.scss */
.super-tabs {
  height: 32px;
  position: relative; }

/* line 1974, ../scss/master.scss */
.super-tabs ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: -200px; }

/* line 1981, ../scss/master.scss */
.super-tabs li {
  float: left;
  text-align: center;
  margin: 0 3px 0 0; }

/* line 1986, ../scss/master.scss */
.super-tabs li.super-tabs__tab--active a {
  background-color: #004e90; }

/* line 1989, ../scss/master.scss */
.super-tabs li a {
  display: block;
  font-weight: bold;
  color: #fff;
  height: 32px;
  border-radius: 6px 6px 0 0;
  padding: 8px 5px;
  width: 150px;
  background: #7fb2dd;
  box-sizing: border-box; }

/* line 2000, ../scss/master.scss */
.super-tabs li a:hover {
  text-decoration: none;
  background-color: #004e90; }

/*# sourceMappingURL=master.css.map */
